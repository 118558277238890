<template>
    <section>
        <div class="row mx-0 border-bottom align-items-center p-1" style="height:44px;">
            <div class="col-6 f-18 f-500 text-general">
                Cambio de precio de los productos
            </div>
            <el-popover
            ref="excel"
            placement="bottom"
            popper-class="br-8 border"
            trigger="click"
            >
                <div class="row mx-0 br-6 cr-pointer align-items-center px-2 item text-general2 f-14" style="height:32px;" @click="subirArchivo">
                    Subir archivo
                </div>
                <div class="row mx-0 br-6 cr-pointer align-items-center px-2 item text-general2 f-14" style="height:32px;">
                    <vue-excel-xlsx
                    class="btn-excel"
                    :data="examplePlantillaBarcode"
                    :columns="fieldsPlantillaBarcode"
                    :filename="'plantilla_barcode'"
                    :sheetname="'sheetname'"
                    >
                        <!--<i class="icon-descargar f-14 text-general2" />-->
                        <span class="text-general2 f-14">Descargar plantilla barcode</span>
                    </vue-excel-xlsx>
                </div>
                <div class="row mx-0 br-6 cr-pointer align-items-center px-2 item text-general2 f-14" style="height:32px;">
                    <vue-excel-xlsx
                    class="btn-excel"
                    :data="examplePlantillaSku"
                    :columns="fieldsPlantillaSku"
                    :filename="'plantilla_sku'"
                    :sheetname="'sheetname'"
                    >
                        <!--<i class="icon-descargar f-14 text-general2" />-->
                        <span class="text-general2 f-14">Descargar plantilla sku</span>
                    </vue-excel-xlsx>
                </div>
                <div v-if="info_cedis.id_cedis_catalogo === null && $can('boton_productos_cambio_precio')" slot="reference" class="btn-general text-white f-14 px-2">
                    Cambiar precio <i class="icon-angle-down f-20" />
                </div>
            </el-popover>
            <div class="col-4 ml-auto">
                <el-input v-model="buscar" size="small" placeholder="Buscar producto" class="br-20" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12 px-0 cont-table custom-scroll overflow-auto" style="height:calc(100vh - 241px)">
                <el-table
                :data="productos.filter(data => !buscar || data.nombre.toLowerCase().includes(buscar.toLowerCase()) || data.responsable.toLowerCase().includes(buscar.toLowerCase()))"
                style="width: 100%"
                header-row-class-name="text-general f-16"
                @row-click="verHistorial"
                >
                    <el-table-column fixed class-name="text-center" width="80">
                        <template slot-scope="{row}">
                            <img :src="row.imagen" width="49" height="49" class="obj-cover br-5" />
                        </template>
                    </el-table-column>
                    <el-table-column fixed label="Productos" width="350">
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <p class="col-12 px-0">
                                    {{ !row.barcode ? row.sku : row.barcode }} - {{ row.nombre }} - {{ row.presentacion }}
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Precio Inicial" min-width="180" align="center">
                        <template slot-scope="{row}">
                            <div>
                                {{ convertMoney(row.valor_inicio , idm_moneda) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Precio Cambio" min-width="180" align="center">
                        <template slot-scope="{row}">
                            <div>
                                {{ convertMoney(row.valor, idm_moneda) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="Fecha" label="Entrega" min-width="180" align="center">
                        <template slot-scope="{row}">
                            <div>
                                {{ row.created_at | helper-fecha('DD - MMM Y HH:mm a') }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="responsable"

                    width="180"
                    label="Responsable"
                    />
                </el-table>
            </div>
        </div>
        <!-- Partials -->
        <modal-historial-cambios ref="historialCambios" />
        <modal ref="modalSubirArchivo" titulo="Subir Archivo" :cargado-modal="cargando" no-aceptar adicional="Cargar" @adicional="cargarArchivo">
            <div class="row mx-0 justify-center">
                <div class="col-8">
                    <template>
                        <el-radio v-model="tipo" label="barcode">
                            Barcode
                        </el-radio>
                        <el-radio v-model="tipo" label="sku">
                            Sku
                        </el-radio>
                    </template>
                </div>
            </div>
            <div class="row mx-0 justify-center mt-3">
                <div class="col-8">
                    <el-upload
                    ref="upload"
                    class="upload-demo"
                    :auto-upload="false"
                    action="#"
                    :on-change="changeFile"
                    :on-remove="removeFile"
                    :multiple="false"
                    :limit="1"
                    accept=".xlsx"
                    >
                        <div class="btn-grey px-2 f-14">
                            Click para subir archivo
                        </div>
                    </el-upload>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import VueExcelXlsx from "vue-excel-xlsx";
import excel from 'vue-excel-export'
import ProductosAdmin from "~/services/productos/productosAdmin"
import {mapGetters} from 'vuex'
import Vue from 'vue'
Vue.use(excel)
Vue.use(VueExcelXlsx)
export default {
    components: {
        modalHistorialCambios: () => import('../../partials/modalHistorialCambios')
    },
    props:{
        categoria:{
            type: Number,
            default: 0
        },
    },
    data(){
        return {
            cargando: false,
            tipo: 'barcode',
            buscar: '',
            productos:[],
            productos_excel: [],
            examplePlantillaBarcode:[{barcode:'',precio_nuevo:''}],
            examplePlantillaSku: [{sku:'',precio_nuevo:''}],
            file: null,
        }
    },
    computed:{
        ...mapGetters({
            info_cedis:'cedis/info_cedis',
        }),
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        },
        idm_moneda(){
            return this.cedis_calculo.find(o=>o.id == this.id_cedis).idm_moneda
        },
        fieldsPlantillaBarcode(){
            return[
                {
                    label: "barcode",
                    field: "barcode",
                },
                {
                    label: "precio_nuevo",
                    field: "precio_nuevo",
                },
            ]
        },
        fieldsPlantillaSku(){
            return[
                {
                    label: "sku",
                    field: "sku",
                },
                {
                    label: "precio_nuevo",
                    field: "precio_nuevo",
                },
            ]
        },
    },
    watch:{
        categoria(val){
            if(val != 0){
                this.getProductos()
            }
        }
    },
    methods:{
        async getProductos(){
            try {
                console.log(this.id_cedis + " - " + this.categoria);
                const {data} = await ProductosAdmin.getProductosHistorialPrecio(this.id_cedis, this.categoria)
                this.productos = data.productos
            } catch (error){
                this.error_catch(error)
            }
        },
        verHistorial(item){
            const obj = {id_cedis:this.id_cedis, id_producto:item.id_producto}
            this.$refs.historialCambios.toggle(obj,item);
        },
        subirArchivo(){
            this.file = null;
            this.$refs.upload.clearFiles();
            this.$refs.excel.doToggle()
            this.$refs.modalSubirArchivo.toggle();
        },
        changeFile(file){
            let ext = this.getFileExtension(file.name);
            if (ext != 'xlsx'){
                this.notificacion('Mensaje', 'El archivo que intenta subir no cumple con la extensión requerida (xlsx)','warning');
                this.removeFile();
                return;
            }
            this.file = file.raw
        },
        getFileExtension(filename){
            return filename.split('.').pop()
        },
        removeFile(){
            this.$refs.upload.clearFiles();
            this.file = null
        },
        async cargarArchivo(){
            try {
                if(this.file === null) return this.notificacion('Mensaje', 'Debe seleccionar un archivo (ext: xlsx)','warning');
                if (this.id_cedis === null || this.id_cedis === undefined || this.id_cedis === ''){return}
                let model = {
                    id_cedis: this.id_cedis,
                    file: this.file,
                    tipo: this.tipo
                };
                this.cargando = true;
                model = this.crear_formData(model)
                const {data} = await ProductosAdmin.postImportarCambioPreciosProductos(model)
                this.$refs.modalSubirArchivo.toggle();
                if (data.datos.length < 1) return this.notificacion('Mensaje', 'No se encontraron registros', 'warning')
                this.$router.push({name: 'admin.cedis.importar-archivo', params: {id_cedis: this.id_cedis, datos:data.datos, tipo:this.tipo, cedis:data.cedis}});
            } catch (error){
                this.notificacion('¡No es posible importar el archivo!', error.response.data.titulo, 'warning');
            } finally {
                this.cargando = false;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.item:hover{
    background-color: #F5F5F5 !important;
}
</style>
